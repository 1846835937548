.top-title {
  text-decoration: none;
  color: white;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.fixed-top {
  border-bottom: 1px solid #3e3f41;
  background-color: #181919;
}
.top-title:hover {
  color: white;
}
#ab {
  color: white;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-align: center;
}
.title {
  text-align: center;
  text-decoration: none;
  color: white;
  display: block;
  background-color: #181919;
  border-bottom: 1px solid #3e3f41;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 13px;
  padding: 3px;
}
.title:hover {
  color: white;
  background-color: #3a3d3d;
}
