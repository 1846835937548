.thumbnail {
  margin: 15px;
  background-color: #242526;
  border-radius: 0px 0px 15px 15px;
}
.video {
  width: 640px;
  height: 420px;
  max-height: 100%;
  max-width: 100%;
  border-bottom: 1px solid #3e3f41;
}
.info {
  background-color: rgb(0, 0, 0);
  color: white;
  justify-content: space-between;
}
.links {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold",
    sans-serif "Lucida Sans", Arial, sans-serif;
  text-decoration: none;
  color: #6e7074;
  padding: 5px;
  display: block;
  text-align: center;
  border-radius: 45px;
  border-bottom: 2px solid #3e3f41;
  box-shadow-bottom: 1px 2px 10px red;
}
.links:hover {
  color: #d2d4d6;
}
.tester {
  padding-top: 50px;
}
p {
  color: white;
  text-align: center;
}
.btn-true {
  text-align: center;
  border-radius: 15px;
  border: none;
  padding: 5px;
  background-color: rgb(255, 123, 0);
  color: white;
  width: 100px;
}
.btn-true:hover {
  background-color: rgb(255, 179, 109);
}
.btn-block {
  border-radius: 15px;
  border: none;
  padding: 5px;
  background-color: rgb(52, 33, 16);
  color: rgb(60, 60, 60);
  width: 100px;
}

.vid-stats {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold",
    sans-serif "Lucida Sans", Arial, sans-serif;
  text-decoration: none;
  color: #6e7074;
  padding: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 45px;
  float: right;
}
